import { DateFormat, DateUtils, IItem } from "@warranty-keeper/common";
import { getLeftTimeString } from "../../../utils/DateUtil";
import ItemProgressBar from "../ItemProgressBar/ItemProgressBar";
import styles from "./ItemPreview.module.scss";
import { CustomImage } from "../../basic/CustomImage";
import classNames from "classnames";
import { translate } from "../../../locale/Locale";

export interface ItemPreviewProps {
  item: IItem;
  dateFormat: DateFormat;
  onClick: () => void;
}

export function ItemPreview(props: ItemPreviewProps) {
  const expired = DateUtils.hasItemExpired(props.item);

  return (
    <div className={styles.container} data-item-id={props.item.id}>
      <div onClick={props.onClick} className={styles.card}>
        <div className={styles.imageContainer}>
          <CustomImage
            imageUrl={props.item.imageUrl}
            className={styles.image}
          />
        </div>

        <div className={styles.contentColumn}>
          <div className={styles.cardHeader}>
            <div className={styles.title} data-test-id="itemName">
              {props.item.name}
            </div>

            <div className={styles.subtitle}>
              {expired
                ? translate("itemPreview.expiredOn") +
                  ": " +
                  DateUtils.formatMillis(
                    props.item.warrantyVoidsDateMillis,
                    props.dateFormat,
                  )
                : translate("itemPreview.willExpireOn") +
                  ": " +
                  DateUtils.formatMillis(
                    props.item.warrantyVoidsDateMillis,
                    props.dateFormat,
                  )}
            </div>
          </div>

          <div>
            {expired ? (
              <div className={classNames(styles.bottomText, styles.expired)}>
                {translate("itemPreview.expired")}
              </div>
            ) : (
              <div className={styles.bottomText}>
                {getLeftTimeString(
                  props.item.warrantyVoidsDateMillis,
                  translate,
                )}
              </div>
            )}
            <ItemProgressBar
              purchaseDateMillis={props.item.purchaseDateMillis}
              warrantyVoidsDateMillis={props.item.warrantyVoidsDateMillis}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
