import { CategoryKey, ICategoryPreview } from "@warranty-keeper/common";
import { useState } from "react";
import CategoryPreview from "../CategoryPreview";
import styles from "./CategoryList.module.scss";

export interface CategoryListProps {
  categories: ICategoryPreview[];
  onClickCategory: (categoryKey: CategoryKey) => void;
  onDeleteCategory: (categoryKey: CategoryKey) => void;
}

export default function CategoryList(props: CategoryListProps) {
  const [swipedCategoryKey, setSwipedCategoryKey] = useState<
    CategoryKey | undefined
  >();

  return (
    <div className={styles.container}>
      {props.categories.map((category) => (
        <CategoryPreview
          key={category.categoryKey}
          category={category}
          onClick={props.onClickCategory}
          onDelete={props.onDeleteCategory}
          editMode={swipedCategoryKey === category.categoryKey}
          startEditMode={setSwipedCategoryKey}
          endEditMode={(categoryKey) => {
            if (categoryKey === swipedCategoryKey) {
              setSwipedCategoryKey(undefined);
            }
          }}
          isTheOnlyCategory={props.categories.length <= 1}
        />
      ))}
    </div>
  );
}
