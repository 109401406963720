import {
  DateFormat,
  DateUtils,
  IItem,
  StringUtils,
  Variant,
} from "@warranty-keeper/common";
import { CustomImage } from "../../components/basic/CustomImage";
import styles from "./ItemScreen.module.scss";
import { getCategoryName } from "../../utils/CategoryUtil";
import { getLeftTimeString } from "../../utils/DateUtil";
import IconButton from "../../components/basic/IconButton";
import Screen from "../../components/basic/Screen/Screen";
import ItemProgressBar from "../../components/item/ItemProgressBar/ItemProgressBar";
import Button from "../../components/basic/Button";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import Popup from "../../components/basic/Popup";
import { useState } from "react";
import { IconColorField } from "../../components/basic/IconButton/IconButton";
import Illustration from "../../components/basic/Illustration/Illustration";
import DeleteItemLight from "../../assets/illustrations/delete-item-light.svg";
import DeleteItemDark from "../../assets/illustrations/delete-item-dark.svg";
import { MediaFileMethod, RawMediaFile } from "../../utils/MediaFileUtils";
import MediaPickerModal from "../../components/item/MediaField/MediaPickerModal";
import { translate } from "../../locale/Locale";

export interface ItemScreenProps {
  item: IItem;
  dateFormat: DateFormat;
  onEdit: () => void;
  onViewReceiptButton: () => void;
  onAddReceipt: (file: RawMediaFile | undefined) => void;
  onDeleteItem: () => void;
  onItemImageClicked: () => void;
}

export default function ItemScreen(props: ItemScreenProps) {
  const item = props.item;
  const expired = DateUtils.hasItemExpired(item);

  const EditButton = (
    <IconButton
      onClick={props.onEdit}
      icon={EditIcon}
      iconColorField={IconColorField.FILL}
      className={styles.editButton}
      analyticTag={"item/edit"}
      testId="editItem"
    />
  );

  return (
    <Screen headerTitle={item.name} rightButton={EditButton}>
      <div className={styles.itemScreen}>
        <div className={styles.imageContainer}>
          <CustomImage
            imageUrl={item.imageUrl}
            className={styles.image}
            onClick={props.onItemImageClicked}
            testId="item-image"
          />
        </div>

        <div className={styles.content}>
          <ProgressBar expired={expired} item={item} />

          <ItemFields item={item} dateFormat={props.dateFormat} />

          <div className={styles.actionBox}>
            <ReceiptButton {...props} />
            <DeleteButton {...props} />
          </div>
        </div>
      </div>
    </Screen>
  );
}

function ItemField(props: { fieldKey: string; value: string | number }) {
  return (
    <div className={styles.itemField}>
      <div className={styles.itemFieldTitle}>
        {translate("item." + props.fieldKey)}
      </div>
      <div>{props.value}</div>
    </div>
  );
}

function ReceiptButton(props: ItemScreenProps) {
  const [showAddReceiptPicker, setShowAddReceiptPicker] = useState(false);
  const [error, setError] = useState("");

  const receipts = props.item.receipts;
  if (receipts.length === 0) {
    return (
      <div className={styles.actionButton}>
        <Button
          title={translate("receipt.addReceipt")}
          onClick={() => setShowAddReceiptPicker(true)}
          analyticTag={"item/addReceipt"}
        />

        {error && <div className={styles.receiptUploadError}>{error}</div>}

        <MediaPickerModal
          open={showAddReceiptPicker}
          onClose={() => setShowAddReceiptPicker(false)}
          methods={[
            MediaFileMethod.TAKE_PHOTO,
            MediaFileMethod.UPLOAD_IMAGE,
            MediaFileMethod.UPLOAD_PDF,
          ]}
          onLocalFilePicked={props.onAddReceipt}
          setError={setError}
        />
      </div>
    );
  }

  return (
    <div className={styles.actionButton}>
      <Button
        title={translate("receipt.viewReceipt")}
        onClick={props.onViewReceiptButton}
        analyticTag={"item/viewReceipt"}
      />
    </div>
  );
}

function DeleteButton(props: ItemScreenProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton
        icon={TrashIcon}
        title={translate("item.deleteItem")}
        onClick={() => setOpen(true)}
        iconColorField={IconColorField.STROKE}
        className={styles.deleteButton}
        analyticTag={"item/delete"}
      />
      <Popup
        open={open}
        title={translate("common.deletingSomething", {
          something: props.item.name,
        })}
        content={translate("common.areYouSure")}
        illustration={
          <Illustration lightPath={DeleteItemLight} darkPath={DeleteItemDark} />
        }
        buttons={[
          {
            label: translate("common.cancel"),
            variant: Variant.Info,
            onClick: () => setOpen(false),
          },
          {
            label: translate("common.delete"),
            variant: Variant.Secondary,
            onClick: props.onDeleteItem,
          },
        ]}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

function ProgressBar(props: { expired: boolean; item: IItem }) {
  return (
    <div className={styles.itemProgressBar}>
      {props.expired
        ? translate("itemPreview.expired")
        : getLeftTimeString(props.item.warrantyVoidsDateMillis, translate)}
      <ItemProgressBar
        purchaseDateMillis={props.item.purchaseDateMillis}
        warrantyVoidsDateMillis={props.item.warrantyVoidsDateMillis}
      />
    </div>
  );
}

function ItemFields(props: { item: IItem; dateFormat: DateFormat }) {
  const item = props.item;

  return (
    <>
      <div className={styles.fields}>
        <ItemField
          fieldKey="purchaseDate"
          value={DateUtils.formatMillis(
            item.purchaseDateMillis,
            props.dateFormat,
          )}
        />
        <ItemField
          fieldKey="warrantyVoid"
          value={DateUtils.formatMillis(
            item.warrantyVoidsDateMillis,
            props.dateFormat,
          )}
        />
        <ItemField fieldKey="warrantyMonths" value={item.warrantyMonths} />
        <ItemField fieldKey="category" value={getCategoryName(item.category)} />
      </div>

      {!StringUtils.isEmptyString(item.notes) && (
        <ItemField fieldKey="notes" value={item.notes} />
      )}
    </>
  );
}
